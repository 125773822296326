import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { View, StyleSheet, TouchableOpacity, Image, Dimensions, Text} from "react-native";
import { imgVisbility, imgVisbilityOff, backArrow, deleteButton } from "./assets";
import moment from "moment";
// Customizable Area End

import TrashController, {
  Props,
  configJSON,
} from "./TrashController";

export default class Trash extends TrashController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <View style={{ display: "flex", alignItems: "center" }}>
        <View style={styles.mainWrapper}>
          <Typography style={styles.titleText}>{configJSON.labelTitleText}</Typography>
          <Typography style={styles.bodyText}>{configJSON.labelBodyText}</Typography>
          <View style={styles.inputStyle}>
            <Input
              data-test-id="txtInput"
              placeholder={configJSON.txtInputPlaceholder}
              label={`This is the received value: ${this.state.txtSavedValue}`}
              {...this.txtInputProps}
              rightIcon={
                <Button
                  data-test-id="btnTogglePassword"
                  aria-label="toggle password visibility"
                  {...this.btnShowHideProps}
                  style={{ backgroundColor: "" }}
                  icon={this.state.enableField ? (
                    <img src={imgVisbility} />
                  ) : (
                    <img src={imgVisbilityOff} />
                  )}
                />
              }
            />
          </View>
          <View style={styles.buttonStyle}>
            <Button
              data-test-id="btnAddExample"
              gradientColors={[styles.buttonStyle.backgroundColor, styles.buttonStyle.backgroundColor]}
              onPress={() => this.doButtonPressed()}
              text={configJSON.btnExampleTitle} />
          </View>
          <View style={{ marginBottom: 100, width: "100%" }}>
            {this.state.showTrashList ?
              <View>
                <View style={{ flexDirection: 'row', padding: 10, marginBottom: 20 }}>
                  <TouchableOpacity testID={"toggleImageChecked"} style={{ width: '30%' }} onPress={() => this.setTrashListHide()}>
                    <Image source={backArrow} style={{ width: '25%', height: 20 }} resizeMode="cover"></Image>
                  </TouchableOpacity>
                  <Typography
                    data-test-id="labelBody"
                  >{configJSON.backTitleText}</Typography>
                </View>
                <View style={styles.listContainer}>
                  {this.state.trashListItem.map((item, index) => {
                    const date = moment(item.deleted_at);
                    let { image_url, video_url } = item.item_attributes;
                    const mediaUrl = image_url || video_url;
                    const mediaType = this.getMediaType(mediaUrl);
                    return (
                      <View>
                        <View key={item.id} style={styles.imageContainer}>
                          {mediaType === 'image' ? (
                            <Image
                              style={styles.imageStyle}
                              source={{ uri: mediaUrl }}
                              resizeMode="cover"
                            />
                          ) : mediaType === 'video' ? (
                            <video
                              style={styles.imageStyle}
                              src={mediaUrl}
                              controls
                              loop
                            />
                          ) : mediaType === 'pdf' ? (
                            <Button
                              text="Open PDF"
                              onPress={() => this.downloadAndOpenPdf(mediaUrl)}
                            />
                          ) : null}
                        </View>
                        <View key={item.id} style={styles.imageContainer}>
                          <Text>Deleted Date:</Text>
                          <Text>{date.format('DD-MM-YYYY HH:mm')}</Text>
                        </View>
                      </View>
                    );
                  })}
                </View>
              </View>
              :
              <>
                <View style={styles.TitleContainer}>
                  <Typography
                    data-test-id="labelBody"
                    style={styles.body}
                  >
                    {configJSON.labelBodyText}
                  </Typography>
                  <TouchableOpacity testID={"btnShowTrash"} style={styles.deleteContainer} onPress={() => this.setTrashListShow()} >
                    <Text data-test-id="labelBody" style={[styles.body, { marginTop: 45 }]}>{configJSON.labelTitleText}</Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.listContainer}>
                  {this.state.listItem.map((item, index) => {
                    let { image, video } = item.attributes;
                    const mediaUrl = image || video;
                    const mediaType = this.getMediaType(mediaUrl);
                    return (
                      <View key={item.id} style={styles.imageContainer}>
                        {mediaType === 'image' ? (
                          <Image
                            style={styles.imageStyle}
                            source={{ uri: mediaUrl }}
                            resizeMode="cover"
                          />
                        ) : mediaType === 'video' ? (
                          <video
                              style={styles.imageStyle}
                              src={mediaUrl}
                              controls
                              loop
                            />
                        ) : mediaType === 'pdf' ? (
                           <Button
                              text="Open PDF"
                              onPress={() => this.downloadAndOpenPdf(mediaUrl)}
                            />
                        ) : null}
                        <TouchableOpacity
                          testID={"btnDelete" + index}
                          style={{ width: '50px', height: "50px", marginLeft: 5 }}
                          onPress={() => this.deleteListItem(item.id)}
                        >
                          <Image source={deleteButton} style={{ width: '100%', height: '100%' }} resizeMode="cover"></Image>
                        </TouchableOpacity>
                      </View>
                    );
                  })}
                </View>
              </>
            }
          </View>
        </View>
      </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#ffff",
    width: "50%",
    fontFamily: "Roboto, sans-serif"
  },
  listContainer: {
    flex: 1,
  },
  imageContainer: {
    flexDirection: "row",
    alignItems: 'center',
    marginBottom: 10,
  },
  imageStyles: {
    height: Dimensions.get("window").width / 3,
    width: '100%',
    borderWidth: 1,
    borderColor: "#ccc"
  },
  TitleContainer: {
    flex: 1,
    flexWrap: "wrap",
    flexDirection: "row"
  },
  body: {
    marginBottom: 32,
    textAlign: "left",
    marginVertical: 8,
    padding: 10,
  },
  imageStyle: {
    height: Dimensions.get("window").width / 3,
    width: '90%',
    borderWidth: 1,
    borderColor: "#ccc"
  },
  deleteContainer: {
    position: "absolute",
    bottom: 10,
    right: 10,
    zIndex: 1
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",


  },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "rgb(98, 0, 238)",
  },
});


// Customizable Area End